import React from 'react';
import './Fonts.css'

const GradientLineLinkedIn = () => {
  return (
    <div className="gradient-line-linkedin"></div>
  );
};

export default GradientLineLinkedIn;
